.category-item img {
  max-width: 100px;
  border-radius: 10px;
  max-height: 240px;
}

.category-item p {
  text-align: center;
  margin: 10px 0px;
  font-size: 16px;
  color: #000;
}

.category-item a {
  text-decoration: none;
}

.categories-container {
  margin-bottom: 30px;
}

.category-item {
  text-align: center;
  margin: 12px;
}

.category-item {
  background: #0d6efd40;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.category-banner img {
  border-radius: 8px;
}
.category-banner {
  text-align: center;
  margin: 20px 0;
}

h2.cat-section-title {
  display: none;
}

.cat-container,
.cat-container-6 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  padding: 0;
}

.cat-container-6 {
  grid-template-columns: repeat(6, 1fr);
}

.cat-img {
  background: var(--secondary_color);
  padding: 10px 6px 0px 6px;
  border-radius: 9px;
  margin: 4px auto;
  height: 100px;
  max-width: 105px;
  text-align: center;
  box-shadow: 0 2px 5px 0 rgba(90, 90, 90, 0.2),
    0 2px 5px 0 rgba(90, 90, 90, 0.19);
  border: 1px solid var(--primary_color);
}

.cat-img img {
  max-width: 87px;
  /* transform: scale(1.3); */
  filter: brightness(100%);
}

.cat-title a {
  color: #000;
}

.cat-item a {
  text-decoration: auto;
}

.cat-title p {
  text-align: center;
  color: #000;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 12px;
  font-weight: 500;
  max-width: 128px;
  line-height: 15px;
}

@media only screen and (max-width: 991.98px) {
  .cat-container,
  .cat-container-6 {
    grid-template-columns: auto auto auto auto;
  }
}

@media screen and (max-width: 767.98px) {
  .cat-item {
    max-width: 85px;
  }

  .cat-img {
    padding: 10px 5px 0px 5px;
    height: 90px;
  }

  .cat-img img {
    max-width: 70px;
  }

  .cat-title p {
    margin-top: 5px;
    font-size: 12px;
    margin-bottom: 12px;
  }

  h2.cat-section-title {
    display: block;
    font-size: 17px;
    font-weight: 800;
    color: #1f1f1f;
  }
}
