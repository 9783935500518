.main-banner {
  width: 100%;
  /* height: 230px; */
  border-radius: 30px;
  margin-top: 15px;
}

.fullwidth-banner {
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 15px 12px;
}
.childnav-content.banner-slider-view {
  width: 60% !important;
  padding-left: 160px !important;
  height: calc(100vh - 50px);
  overflow-x: auto;
}
@media screen and (max-width: 767.98px) {
  .fullwidth-banner {
    padding: 0px 12px;
  }

  .main-banner {
    height: auto;
    border-radius: 10px;
    margin-top: 0px;
  }
}
