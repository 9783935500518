.banner-img img {
    max-width: calc(100% - 30px);
    border-radius: 10px;
}
.banners-container {
    padding: 30px 0px;
}
.banner-item-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; /* Adjust the gap according to your design */
  }
  
  .banner-img-grid {
    flex-shrink: 0;
    flex-basis: calc(25% - 16px);
    box-sizing: border-box;
  }
  
  .banner-img-grid img {
    max-width: 100%;
    border-radius: 10px;
  }
  .slick-arrow {
    border-radius: 50%;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px rgba(0,0,0,.12);
} 
.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media screen and (max-width: 767.98px) {
    .banner-img img {
        max-width: 100%;
    } 
    .slick-slider .slick-prev:before, .slick-slider .slick-next:before{
        margin-left: 5px;
    }
}
