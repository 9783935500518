.dashboard-layout {
  display: flex;
  height: 100vh;
  overflow: hidden;
}
header {
  position: fixed;
  width: 100%;
}

aside {
  margin-top: 50px;
  display: flex;
  flex: 1;
}

nav.sidebar-main {
  height: calc(100vh - 27px);
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  top: 40px;
}

nav.sidebar-main::-webkit-scrollbar {
  width: 0;
}

.dashboard-content {
  margin-top: 61px;
  flex: 15;
  /* display: flex; */
}

.cards-container {
  display: flex;
  justify-content: space-evenly;
}

.filter-card {
  margin-left: 16px;
  flex: 1;
  height: 100px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 20px 10px -10px;
  background: #1b7d78;
  color: #fff;
}

header {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  display: flex;
  z-index: 2;
  height: 60px;
}

img.DashBoard-toggle {
  max-width: 20px;
  margin-left: 33px;
}

img.DashBoard-Logo {
  max-width: 100px;
  max-height: 70px;
  margin-left: 20px;
  padding: 5px;
}

img.DashBoard-Photo {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

img.DashBoard-Photo.offline {
  transform: translateX(-55px);
}

.Dashboard-brand {
  flex: 7;
  display: flex;
  align-items: center;
}

.menu-right {
  display: flex;
  align-items: center;
}

.sidebar-main {
  background: #ffffff;
  box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.25);
  width: 86px;
  padding-top: 20px;
}

.sidebar-icon {
  font-size: 24px;
}
.table-responsive .gLTUqv {
  height: 400px;
  width: 95%;
}

.sidebar-name {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.sidebar-child {
  background: #ffffff;
  box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.25);
  width: 187px;
  margin-left: 3px;
}

.sidebar-child h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #00769b;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 58px;
  margin-bottom: 0px;
}

.Configiration-list {
  padding: 5px 20px;
}

.Configiration-list1 {
  padding: 5px 20px;
}

/* .Configiration-list:hover a .list-name {
    color: #00769B;
} */

.dashboard-content {
  padding-left: 20px;
  padding-right: 20px;
  /* padding-top: 20px; */
}

.navitem:hover,
.navitem.active {
  border-left: 5px solid #1b7d78;
  margin-left: 0px;
}

.navitem:hover .sidebar-name .navitem.active .sidebar-name {
  color: #034561;
}

.sidebar-main a.navitem.active {
  color: #034561;
  border-left: 5px solid #034561;
  margin-left: 0px;
}

.navitem {
  margin-left: 5px;
}

.dashboard-header {
  display: flex;
  background: #e6e6e6;
}

.dashboard-header a {
  margin: 2px;
}

.month-selector {
  flex: 0.2;
}

.dashboard-header-doctor {
  background: #00ebe6;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  padding: 12px 15px;
}

h5.dashboard-subtitle {
  flex: 1;
  margin-bottom: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #5c5c5c;
  padding-top: 8px;
  padding-bottom: 8px;
}

.dashboard-header {
  padding: 12px 15px;
}

.dashboard-header-emr {
  padding: 12px 15px;
}

.Configiration-list:hover,
.Configiration-list.active {
  background: #f0f8ff;
  box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 25%);
  border-radius: 4px;
}

.configiration-icon {
  max-width: 14px;
  max-height: 14px;
}

.colorChange {
  color: #00769b !important;
  text-decoration: none;
}

.sidebar-icon.acitve {
  font-size: 24px;
  color: #1b7d78 !important;
}

.NoColorChange {
  color: #5c5c5c;
  text-decoration: none;
}

.list-name {
  margin-left: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
}

.navitem {
  text-align: center;
  cursor: pointer;
}

.container-form {
  background: #fff;
  padding: 12px 0px;
  border-radius: 5px;
}

button.btn.btn-white {
  background: #fff;
  color: #1b7d78;
  border-radius: 4px;
  border: 1px solid #1b7d78;
}

button.btn.btn-white:hover {
  background: #1b7d78;
  color: #fff;
  border-radius: 4px;
}

select.btn.btn-white:hover {
  background: #1b7d78;
  color: #fff;
  border-radius: 4px;
}

button.btn.btn-primary {
  background: #1b7d78;
  border-radius: 4px;
}

select.btn.btn-primary {
  background: #1b7d78;
  border-radius: 4px;
}

button.btn.btn-primary:hover {
  color: #1b7d78;
  background: #fff;
  border: 1px solid #00769b;
  border-radius: 4px;
}

button.btn.btn-add-more {
  background: #1b7d78;
  color: #fff;
  border: 1px solid #00769b;
  border-radius: 4px;
  width: 150px;
  margin-left: auto;

  &:hover {
    background: #fff;
    color: #1b7d78;
  }
}

.dashboarlist-left-entries {
  display: flex;
  gap: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5c5c5c;
}

.dashboard-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom: 21px;
  background: #f9fcff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.input-box {
  display: flex;
}

.dashboard-list-filter-main {
  margin-right: 28px;
  cursor: pointer;
}

.dashboard-list-filters {
  width: 18px;
  margin-left: 28px;
  height: 18px;
}

.dashboard-list-number {
  padding-right: 44px;
  border: 0.5px solid #cacaca;
  border-radius: 1px;
}

.dashboard-table-head {
  border-bottom: 0.3px solid #5c5c5c;
  font-family: "Inter";
  padding-top: 16px;
  padding-bottom: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: #1d273b;
}

.dashboard-tabe-data-actions {
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: #1b7d78;
}

.dashboard-tabe-data-actions:hover {
  transition: 0.4s;
  background-color: rgba(0, 0, 0, 0.54);
  border-radius: 50%;
}

.dashboard-table-list-main {
  text-align: center;
  width: 100%;
  background: #ffffff;
  box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1), -2px 0px 2px rgba(0, 0, 0, 0.1),
    0px -2px 2px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.pie-container {
  width: 391px;
  height: 391px;
}

.nav.sidebar-main a {
  text-decoration: none;
  color: #1d273b;
}

.nav.sidebar-main {
  color: #1d273b;
}

.sidebar-main a {
  color: #1d273b;
  text-decoration: none;
}

.DashBoard-Notification {
  color: #5c5c5c;
  height: 20px;
  margin-right: 12px;
  width: 20px;
}

.user-status {
  align-items: center;
  background: #1b7d78;
  background: var(--Primary-Color1, #1b7d78);
  border-radius: 93px;
  display: flex;
  flex-shrink: 0;
  gap: 3px;
  height: 34px;
  margin-right: 12px;
  padding: 4px;
  width: 86px;
}

.user-status.offline {
  background: #5c5c5c;
}

.user-online {
  color: #fff;
  color: var(--Secondary-Color5, #fff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

.user-offline {
  color: #fff;
  color: var(--Secondary-Color5, #fff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
  transform: translateX(27px);
}

button.btn.btn-inactive {
  background: #8c8c8c;
  border-radius: 4px;
  color: white;
  min-width: 143px;
  height: 45px;
}
.input-cat-with-icon .col-4 {
  width: 85%;
}
